export const titles = [
  {
    id: "jackyWang",
    name: "Jacky Wang",
    title: "GO Markets",
    subTitle: "亚洲投研部主管",
    image: "/financial/Jacky1.png",
  },
  {
    id: "mikeHuang",
    name: "Mike Huang",
    title: "GO Markets",
    subTitle: "销售总监",
    image: "/financial/mike1.png",
  },
];

export const imageSizes = [
  {
    default: true,
    sizeLabel: "1920x1080",
  },
  {
    default: false,
    sizeLabel: "1080x1080",
  },
];
